* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(240,240,240);
    /* font-family: 'Playfair Display', serif; */
}
.mainHeader{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  width: 100%;
    margin: auto;
    background-color: #f2f4f0;
    height:150px;
    padding:10px;
}
.header {
    display: flex;
    justify-content: left;
    padding: 20px;
    width: 80%;
    margin: auto;
    background-color: #FFFFFF;
}
.logo{
  width:180px;

}
/* Style.module.css */
.tableContainer
{
  background-color: rgb(212, 212, 210);
  width: 100%;
  margin: auto;
  height: 100vh;
display: flex;
align-items: center;
justify-content: center;
}

.contentItems{
  background-color: #f2f4f0;
  width:80%;
  height:400px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; 
  border-radius: 10px;
}
.contentSection {
    
    /* width: 80%;
   height: 600px; 
   margin: auto;
   text-align: center;
    background-color: #477447;
    padding:7px; */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; /* Set outer container width */
    height: 100vh; /* Set outer container height */
    margin: auto;
    background-color: rgb(240,240,240); /* Adjust as needed */
}

.messageSection {
   
  
    /* width: 200px; /* Adjust width as needed 
     height: 200px; 
    background-color: #da5e5e;

    align-items: center;
   
    padding: 2px;
     */
     width: 200px; /* Set inner container width */
    height: 200px; /* Set inner container height */
    background-color: #da5e5e; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.btnsection {
  display: flex;
  flex-direction:row;
 
align-items: center;
justify-content: center;
margin-right:18px;
}

.heartIcon {
margin-left: 10px;
}



.buttonContainer {
  display: flex;
  gap: 10px;}



  .downloadButton {
    height:28px;
    width:28px; /* Adjust the size of the download symbol */
    
  }
  .downloadButton img:hover
  {
background-color:#448CCB;
  }

.modal {
    display: block;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modalDialog {
    position: relative;
    width: 1000px;
    margin: 10% auto;
  }
  
  .modalContent {
    position: relative;
    background-color:#eab663;
    
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modalHeader {
    padding: 0.5rem;
    /* border-bottom: 1px solid #dee2e6; */
  }
  
  .modalTitle {
    margin-bottom: 0;
  }
  
  .close {
    padding: 0.5rem;
  }
  
  .modalBody {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 1rem;
  }
  
  .modalFooter {
    padding: 1rem;
    /* border-top: 1px solid #dee2e6; */
  }
  
  /* Additional Styles */
  .btn-primary,
  .btn-secondary {
    cursor: pointer;
  }
  .cardName{
    font-size:13px;
    vertical-align: baseline;
  }
  .card {
    width: 200px;
    height: 300px;
   
    perspective: 1000px;
    position: relative;
  cursor: pointer;
  border-radius: 5px;
  }
  
  .cardInner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
   
  }
.flipped .cardInner {
    transform: rotateY(180deg);
   
  }
  
  .cardFront, .cardBack {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'Playfair Display', serif ; */
  font-family: 'Courier New', Courier, monospace;
   padding: 10px;
  
  }
  
  .cardFront {
    background-color: #dbd8d8;
    /* font-family: 'Playfair Display', italic ; */
    font-size: 0.8rem;
   
  }
  
  .cardBack {
    background-color: #ffcc00;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    transform: rotateY(180deg);
    /* display: none; Initially hidden */
    position: relative;
    font-size: 1.2rem;
    font-weight:200;
  }
  
.cardBackTitle{
  position: absolute;
  bottom :10px;
  right:10px;
  
  font-size: 12px;
  color: gray;
}
 .modalBody textarea{
  height: 150px;
  width:300px;
 }
 .staticMessage{
  font-family: georgia,palatino,book antiqua,palatino linotype,serif;
font-size: 18px;
/* font-family: Georgia, 'Times New Roman', Times, serif; */
 }
 .cardName
 {
  font-size: 13px;
 }