* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: white; 
    text-align: center;
}
.mainContainer{
    background-color: rgb(212, 212, 210);
    width: 100%;
    margin: auto;
    height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main{
    background-color: #f2f4f0;
    width:60%; 
    height:400px;
    margin: auto;
    display: flex;
justify-content: center;
align-items: center;
}
.contentWrapper{
    width:80%;
    height:1000px;
    margin: auto;
    background-color: aqua;
    align-items: center;
    justify-content: center;
}
.adminFormSection
{
    width:40%;
    height:200px;
    margin: auto;
background-color: black;
display:flex;
flex-direction: row;
padding:20px;
}
.fromSection{
    text-align: center;
}
.tableContainer{
   width:100%;
 height: 100vh;
   padding-top: 20px;
   background-color:   rgb(212, 212, 210);
 
}
.tableContainerItem{
    width:80%;
margin: auto;
}